/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
/* amasty tabs custom variables */
/* override tabs styling only if amasty tabs is active */
body.am-tabs-view {
  /* tops men */
  /* bottoms men */
  /* bottoms women */
}

body.am-tabs-view .product.data.items {
  margin: 0 -15px 20px;
  padding: 0;
  border-bottom: 1px solid #F2F2F2;
  list-style-type: none;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items {
    position: relative;
    z-index: 1;
    border-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

body.am-tabs-view .product.data.items > .item.title {
  box-sizing: border-box;
  float: none;
  width: 100%;
  margin: 1px 0;
  max-width: none;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.title {
    float: left;
    width: auto;
    margin: 0 1px 0 0;
  }
}

body.am-tabs-view .product.data.items > .item.title a {
  position: relative;
}

body.am-tabs-view .product.data.items > .item.title a:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: inherit;
  color: inherit;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 1px;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.title a:after {
    display: none;
  }
}

body.am-tabs-view .product.data.items > .item.title > .switch {
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #F2F2F2;
  color: #000;
  display: block;
  font-weight: 600;
  line-height: normal;
  font-size: 20px;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid rgba(176, 170, 0, 0.1);
  height: auto;
  overflow: hidden;
  padding: 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.title > .switch {
    text-decoration: none;
    border: 1px solid #F2F2F2;
    border-bottom: none;
    font-size: 25px;
    padding: 30px;
  }
}

body.am-tabs-view .product.data.items > .item.title.active > .switch:focus,
body.am-tabs-view .product.data.items > .item.title.active > .switch:hover {
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.title.active > .switch:focus,
  body.am-tabs-view .product.data.items > .item.title.active > .switch:hover {
    text-decoration: none;
  }
}

body.am-tabs-view .product.data.items > .item.title.active > .switch {
  background-color: #fff;
  color: #b0aa00;
  border-top: 0;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.title.active > .switch {
    border-top: 1px solid #F2F2F2;
    padding-bottom: 30px;
  }
}

body.am-tabs-view .product.data.items > .item.title:not(.disabled) > .switch:active {
  background: #fff;
  color: #b0aa00;
  text-decoration: none;
}

body.am-tabs-view .product.data.items > .item.content {
  box-sizing: border-box;
  float: none;
  margin: 0;
  background: #fff;
  padding: 10px 10px 30px;
  display: none;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.content {
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    width: 100%;
    background: #fff;
    border: 1px solid #F2F2F2;
    padding: 35px;
    margin-top: 90px;
  }
}

body.am-tabs-view .product.data.items > .item.content:before, body.am-tabs-view .product.data.items > .item.content:after {
  content: '';
  display: table;
}

body.am-tabs-view .product.data.items > .active + .content {
  display: block;
}

body.am-tabs-view .amtab-grid-wrapper.-small .amtab-step {
  max-width: 300px;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-small .amtab-step {
    max-width: 190px;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-tops.-men .amtab-step:nth-child(1) {
    max-width: 176px;
    top: 19.3%;
    right: 20px;
    left: auto;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-tops.-men .amtab-step:nth-child(2) {
    max-width: 172px;
    top: 47.5%;
    left: -10px;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-tops.-men .amtab-step:nth-child(3) {
    max-width: 176px;
    right: 0;
    bottom: 70px;
    left: auto;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-bottoms.-men .amtab-step:nth-child(1) {
    top: -8px;
    left: 58%;
    max-width: 174px;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-bottoms.-men .amtab-step:nth-child(2) {
    top: 16%;
    left: 0;
    max-width: 174px;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-bottoms.-men .amtab-step:nth-child(3) {
    right: -6px;
    bottom: 100px;
    left: auto;
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-bottoms.-women .amtab-step:nth-child(1) {
    top: -7px;
    right: 24px;
    max-width: 174px;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-bottoms.-women .amtab-step:nth-child(2) {
    left: 0;
    width: 33vw;
    top: 20%;
    max-width: 174px;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .amtab-grid-wrapper.-bottoms.-women .amtab-step:nth-child(3) {
    right: -20px;
    bottom: 85px;
    left: auto;
    max-width: 190px;
  }
}

body.am-tabs-view .accordion-container h1,
body.am-tabs-view .accordion-container h2,
body.am-tabs-view .accordion-container h3,
body.am-tabs-view .accordion-container h4,
body.am-tabs-view .accordion-container h5 {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #b0aa00;
}

body.am-tabs-view .accordion-container h1 {
  font-size: 26px;
  line-height: normal;
}

body.am-tabs-view .accordion-container h2 {
  font-size: 16px;
  line-height: normal;
}

body.am-tabs-view .accordion-container p {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 0;
  color: #000;
}

body.am-tabs-view .accordion-container ul li {
  padding: 15px 0;
}

body.am-tabs-view .accordion-container ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 3px;
  right: 0;
}

body.am-tabs-view .accordion-container ul li i:before, body.am-tabs-view .accordion-container ul li i:after {
  background-color: #b0aa00;
}

body.am-tabs-view .accordion-container ul li input[type=checkbox] {
  top: 0;
  left: 0;
}

body.am-tabs-view .amtab-grid-wrapper.-large .amtab-head-line .amtab-cell,
body.am-tabs-view .amtab-table-container .amtab-cell .amtab-desc,
body.am-tabs-view .amtab-grid-wrapper.-small .amtab-steps-container .amtab-head,
body.am-tabs-view .amtab-grid-wrapper.-small .amtab-arrow,
body.am-tabs-view .amtab-cell-select .amtab-select {
  color: #b0aa00;
}

@media screen and (min-width: 992px) {
  body.am-tabs-view [id^="tab-label-amcustomtabs_tabs"][data-role="collapsible"] {
    max-width: 14%;
  }
}

@media screen and (min-width: 992px) {
  body.am-tabs-view .product.data.items > .item.title.active > .switch,
  body.am-tabs-view .product.data.items > .item.title.active > .switch:focus,
  body.am-tabs-view .product.data.items > .item.title.active > .switch:hover {
    padding-bottom: 30px;
  }
}

/* this is an empty file in order to override the styles coming from amasty tabs */
body {
  font-feature-settings: initial;
}
