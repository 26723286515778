/* amasty tabs custom variables */
$am-tabs-bg-color: #F2F2F2;
$am-tabs-color: map-get($theme-colors, 'dark');
$am-tabs-bg-color-active: $white;
$am-tabs-color-active: map-get($theme-colors, 'primary');
$am-tabs-content-bg-color: $white;
$am-tabs-content-color: map-get($theme-colors, 'dark');
$am-tabs-content-headings-color: map-get($theme-colors, 'primary');
$am-tabs-content-faq-icons-color: map-get($theme-colors, 'primary');
$am-tabs-table-cell-color: map-get($theme-colors, 'primary');
$am-tabs-border-color: #F2F2F2;
$am-tabs-border-color-active: $white;
$am-tabs-mobile-accordion-icons-color: map-get($theme-colors, 'primary');
