/* override tabs styling only if amasty tabs is active */
body.am-tabs-view {
    .product.data.items {
        margin: 0 -15px 20px;
        padding: 0;
        border-bottom: 1px solid $am-tabs-border-color;
        list-style-type: none;

        @include respond-to-up($tweakpoint-nav-toggle) {
            position: relative;
            z-index: 1;
            border-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }

        >.item.title {
            box-sizing: border-box;
            float: none;
            width: 100%;
            margin: 1px 0;
            max-width: none;

            @include respond-to-up($tweakpoint-nav-toggle) {
                float: left;
                width: auto;
                margin: 0 1px 0 0;
            }

            a {
                position: relative;

                &:after {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 14px;
                    line-height: inherit;
                    color: inherit;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                    position: absolute;
                    right: 10px;
                    top: 1px;

                    @include respond-to-up($tweakpoint-nav-toggle) {
                        display: none;
                    }
                }
            }

            >.switch {
                font-family: $font-family-base;
                background: $am-tabs-bg-color;
                color: $am-tabs-color;
                display: block;
                font-weight: 600;
                line-height: normal;
                font-size: 20px;
                border-top: 1px solid $am-tabs-border-color;
                border-bottom: 1px solid rgba(theme-color('primary'), .1);
                height: auto;
                overflow: hidden;
                padding: 10px 20px;
                text-overflow: ellipsis;
                white-space: nowrap;

                @include respond-to-up($tweakpoint-nav-toggle) {
                    text-decoration: none;
                    border: 1px solid $am-tabs-border-color;
                    border-bottom: none;
                    font-size: 25px;
                    padding: 30px;
                }
            }

            &.active>.switch:focus,
            &.active>.switch:hover {
                text-decoration: none;

                @include respond-to-up($tweakpoint-nav-toggle) {
                    text-decoration: none;
                }
            }

            &.active>.switch {
                background-color: $am-tabs-bg-color-active;
                color: $am-tabs-color-active;
                border-top: 0;

                @include respond-to-up($tweakpoint-nav-toggle) {
                    border-top: 1px solid $am-tabs-border-color;
                    padding-bottom: 30px;
                }
            }

            &:not(.disabled)>.switch:active {
                background: $am-tabs-bg-color-active;
                color: $am-tabs-color-active;
                text-decoration: none;
            }
        }
        >.item.content {
            box-sizing: border-box;
            float: none;
            margin: 0;
            background: #fff;
            padding: 10px 10px 30px;
            display: none;

            @include respond-to-up($tweakpoint-nav-toggle) {
                box-sizing: border-box;
                float: right;
                margin-left: -100%;
                width: 100%;
                background: $am-tabs-content-bg-color;
                border: 1px solid $am-tabs-border-color;
                padding: 35px;
                margin-top: 90px;
            }

            &:before,
            &:after {
                content: '';
                display: table;
            }
        }
    }

    .product.data.items>.active+.content {
        display: block;
    }

    .amtab-grid-wrapper.-small .amtab-step {
        max-width: 300px;

        @include respond-to-up($tweakpoint-nav-toggle) {
            max-width: 190px;
        }
    }

    /* tops men */

    .amtab-grid-wrapper.-tops.-men .amtab-step:nth-child(1) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            max-width: 176px;
            top: 19.3%;
            right: 20px;
            left: auto;
        }
    }
    .amtab-grid-wrapper.-tops.-men .amtab-step:nth-child(2) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            max-width: 172px;
            top: 47.5%;
            left: -10px;
        }
    }
    .amtab-grid-wrapper.-tops.-men .amtab-step:nth-child(3) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            max-width: 176px;
            right: 0;
            bottom: 70px;
            left: auto;
        }
    }

    /* bottoms men */
    .amtab-grid-wrapper.-bottoms.-men .amtab-step:nth-child(1) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            top: -8px;
            left: 58%;
            max-width: 174px;
        }
    }

    .amtab-grid-wrapper.-bottoms.-men .amtab-step:nth-child(2) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            top: 16%;
            left: 0;
            max-width: 174px;
        }
    }

    .amtab-grid-wrapper.-bottoms.-men .amtab-step:nth-child(3) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            right: -6px;
            bottom: 100px;
            left: auto;
            max-width: 100%;
        }
    }

    /* bottoms women */
    .amtab-grid-wrapper.-bottoms.-women .amtab-step:nth-child(1) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            top: -7px;
            right: 24px;
            max-width: 174px;
        }
    }

    .amtab-grid-wrapper.-bottoms.-women .amtab-step:nth-child(2) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            left: 0;
            width: 33vw;
            top: 20%;
            max-width: 174px;
        }
    }

    .amtab-grid-wrapper.-bottoms.-women .amtab-step:nth-child(3) {
        @include respond-to-up($tweakpoint-nav-toggle) {
            right: -20px;
            bottom: 85px;
            left: auto;
            max-width: 190px;
        }
    }

    .accordion-container h1,
    .accordion-container h2,
    .accordion-container h3,
    .accordion-container h4,
    .accordion-container h5 {
        font-family: $font-family-heading;
        color: $am-tabs-content-headings-color;
    }

    .accordion-container h1 {
        font-size: 26px;
        line-height: normal;
    }

    .accordion-container h2 {
        font-size: 16px;
        line-height: normal;
    }

    .accordion-container p {
        font-size: 14px;
        line-height: normal;
        margin-bottom: 0;
        color: $am-tabs-content-color;
    }

    .accordion-container ul li {
        padding: 15px 0;

        i {
            position: absolute;
            transform: translate(-6px, 0);
            margin-top: 3px;
            right: 0;

            &:before,
            &:after {
                background-color: $am-tabs-content-faq-icons-color;
            }
        }

        input[type=checkbox] {
            top: 0;
            left: 0;
        }
    }

    .amtab-grid-wrapper.-large .amtab-head-line .amtab-cell,
    .amtab-table-container .amtab-cell .amtab-desc,
    .amtab-grid-wrapper.-small .amtab-steps-container .amtab-head,
    .amtab-grid-wrapper.-small .amtab-arrow,
    .amtab-cell-select .amtab-select {
        color: $am-tabs-table-cell-color;
    }

    [id^="tab-label-amcustomtabs_tabs"][data-role="collapsible"] {
        @include respond-to-up($tweakpoint-nav-toggle) {
            max-width: 14%;
        }
    }

    .product.data.items > .item.title.active > .switch,
    .product.data.items > .item.title.active > .switch:focus,
    .product.data.items > .item.title.active > .switch:hover {
        @include respond-to-up($tweakpoint-nav-toggle) {
            padding-bottom: 30px;
        }
    }
}
