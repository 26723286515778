// Colors
$white: #fff;
$black: #000;

// Color Palette
$colors: (
    'green': #b0aa00,
    'green-light': #D7D38A,
    'black': #000,
    'light-grey': #97999C,
    'lighter-grey': #eee,
    'white': #ffffff,
    'green-haze': #03a84e
);

// Theme Colors
$theme-colors: (
    'primary':      map_get($colors, 'green'),
    'secondary':    map_get($colors, 'green-light'),
    'light':        map_get($colors, 'lighter-grey'),
    'dark':         map_get($colors, 'black'),
    'positive':     map-get($colors, 'green-haze')
);
